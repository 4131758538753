import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ColumnSetting, SmartTableOptions } from 'src/app/shared/maersk-smart-table/maersk-smart-table.model';
import { DataService } from 'src/app/services/data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppSettings } from 'src/app/appSettings';

@Component({
  selector: 'app-trigger-product-tables-display',
  templateUrl: './trigger-product-tables-display.component.html',
  styleUrls: ['./trigger-product-tables-display.component.css']
})
export class TriggerProductTablesDisplayComponent implements OnInit {
  @Input() productId: Number;
  @Input() productFamilies: any[];
  currentProductId: Number;
  trigger: any = {};

  tableIds: any;
  tableSettings: ColumnSetting[];
  maerskData: any[];
  tableOptions: SmartTableOptions;

  constructor(private dataService: DataService, private modalService: NgbModal) { }

  ngOnInit() {
    this.tableOptions = AppSettings.tabSettings['Triggers'].tableOptions;
      this.tableSettings = AppSettings.tabSettings['Reference'].tableSettings;
      this.getData();
  }

  ngOnChanges() {
    if (this.productId) {
      this.currentProductId = this.productId;
      this.getData();
    }
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   const prId: SimpleChange = changes.productId;
  //   console.log('prev value: ', name.previousValue);
  //   console.log('got name: ', name.currentValue);
  //   this._name = name.currentValue.toUpperCase();
  // }

  OpenAddUser(addModal) {
    this.modalService.open(addModal);
    }

  getData() {
    if (this.productId) {
    this.dataService.getTriggerTablesData(this.productId.toString()).subscribe(data => {
      this.maerskData = data;
    });
  }
  }

  triggerTables() {
    this.tableIds = this.maerskData.map(function(item) {
      if (item['checked'] && item['checked'] === true) {
       return item['tableId'];
      }
    }).filter(function (tableId) {
      return tableId != undefined;
    });
    this.trigger.productId = this.currentProductId;
    this.trigger.tableIds = this.tableIds;
    let productFamilySelected: any = this.productFamilies.find((itemTrigger) => itemTrigger.familyId == this.productId);
    this.trigger.productName = productFamilySelected.productFamily;
    this.dataService.CreateTriggers(this.trigger).subscribe(data => {
      alert(data['statusMessage']);
    });
  }

  actionRaised(func) {
    let userObj = func[1];
    console.log(userObj);
    let action: number = func[0];
    //1- Add User 4-Delete
    if (action === 1) {
      this.dataService.CreateUser(userObj)
        .subscribe(result => {
          console.log('Add User Successful');
        },
        error => {
          console.log('Add User API Call failed');
        },
        () => {
          // 'onCompleted' callback.
          // No errors, route to new page here
        });
    } else if (action === 4) {
      this.dataService.DeleteUser(userObj)
        .subscribe(
        result => {
          console.log('Delete Successful');
        },
        error => {
          console.log('Delete failed');
        },
        () => {
          this.getData();
        }
        );
    }
  }

}
