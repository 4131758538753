import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { catchError } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import { throwError } from 'rxjs';
import 'rxjs/add/operator/catch';

import { Http, Headers, RequestOptions, ResponseContentType } from '@angular/http';

import { AppSettings } from '../appSettings';

import * as FileSaver from 'file-saver';


@Injectable()
export class DataService {

  currentDate: String = new Date().toLocaleDateString('en-US');
  appBaseUrl: string = AppSettings.API_ENDPOINT;

  constructor(private http: HttpClient) { }

  //Login
  Login(userName, password) {

  }

 getUserRoles(): Observable<any[]> {
    return this.http.get<any[]>(this.appBaseUrl + 'GetUserRoles' );
  }

  getScript(tableName: string , familyName:string) : Observable<any[]> {
    return this.http.get<any[]>(this.appBaseUrl + 'generateScript/' + tableName+'/' +familyName );
  }
  getData(queryString: string): Observable<any[]> {
    return this.http.get<any[]>(this.appBaseUrl + 'getData/' + queryString );
  }

  getUserProductRolesData(queryString: string): Observable<any[]> {
    return this.http.get<any[]>(this.appBaseUrl + 'getUsersForProduct/' + queryString );
  }

  getUsersForTemplateAccessId(queryString: string): Observable<any[]> {
    return this.http.get<any[]>(this.appBaseUrl + 'getUsersForTemplateAccess/' + queryString );
  }

  getTemplateAccessFromTemplateAccessId(queryString: string): Observable<any[]> {
    return this.http.get<any[]>(this.appBaseUrl + 'getAccessTypeForAccessTypeId/' + queryString );
  }

  getTemplateAccessDataForTableId(queryString: string): Observable<any[]> {
    return this.http.get<any[]>(this.appBaseUrl + 'getAccessTypesForTableId/' + queryString );
  }

  getAccessTypeForTableId(queryString: string): Observable<any[]> {
    return this.http.get<any[]>(this.appBaseUrl + 'getAccessTypesForTableId/' + queryString );
  }

  getTriggerTablesData(queryString: string): Observable<any[]> {
    return this.http.get<any[]>(this.appBaseUrl + 'getTriggerTablesForProduct/' + queryString );
  }

  getExplicitAccessTablesData(queryString: string): Observable<any[]> {
    return this.http.get<any[]>(this.appBaseUrl + 'getExplicitAccessTablesForProduct/' + queryString );
  }

  getUserRolesData(): Observable<any[]> {
    return this.http.get<any[]>(this.appBaseUrl + 'getData/AllRolesForAdmin');
  }

  getTableDataWithProduct(): Observable<any[]> {
    return this.http.get<any[]>(this.appBaseUrl + 'getData/ReferenceWithProduct' );
  }

  getManualUploadTablesWithProduct(): Observable<any[]> {
    return this.http.get<any[]>(this.appBaseUrl + 'getData/ManualUpload' );
  }

  getEnvVariables(): Observable<any[]> {
    return this.http.get<any[]>(this.appBaseUrl + 'getenv' );
  }

  getMasterDataTablesWithProduct(): Observable<any[]> {
    return this.http.get<any[]>(this.appBaseUrl + 'getData/MasterDataTables' );
  }

  updateMasterDataTablesWithProduct(formData): Observable<any[]> {
    return this.http.post<any[]>(this.appBaseUrl + 'updateMasterDataTables' , formData)
    .pipe(catchError(this.handleError));
  }
 
  getExplicitAccessTablesWithProduct(): Observable<any[]> {
    return this.http.get<any[]>(this.appBaseUrl + 'getData/ExplicitAccessTables' );
  }

  getProductFamilies(): Observable<any[]> {
    return this.http.get<any[]>(this.appBaseUrl + 'getData/ProductFamily' );
  }

  getAllProductFamiliesForAdmins(): Observable<any[]> {
    return this.http.get<any[]>(this.appBaseUrl + 'getData/ProductFamilyForAdmins' );
  }

  getAllProductFamilyForAdminAndReloader(): Observable<any[]> {
    return this.http.get<any[]>(this.appBaseUrl + 'getData/ProductFamilyForAdminAndReloader' );
  }

  getTableData(queryString: string): Observable<any> {
    return this.http.get<any>(this.appBaseUrl + 'getTableData/' + queryString);
  }

  getTableSchemaData(queryString: string): Observable<any> {
    return this.http.get<any>(this.appBaseUrl + 'getTableSchema/' + queryString);
  }

  //File Upload APIs
  UploadFile(formData): Observable<any> {
    return this.http.post<any>(this.appBaseUrl + 'FileUpload', formData)
      .pipe(catchError(this.handleError));
  }

  CreateTable(formData): Observable<boolean> {
    return this.http.post<boolean>(this.appBaseUrl + 'CreateTable', formData).pipe(catchError(this.handleError));
  }

  CreateTriggers(formData): Observable<boolean> {
    return this.http.post<boolean>(this.appBaseUrl + 'TriggerTables', formData).pipe(catchError(this.handleError));
  }

  CreateUser(formData): Observable<boolean> {
    return this.http.post<boolean>(this.appBaseUrl + 'AddUserWithProductRole', formData).pipe(catchError(this.handleError));
  }

  CreateTemplateUserAccess(formData): Observable<boolean> {
    return this.http.post<boolean>(this.appBaseUrl + 'AddUserTemplateAccess', formData).pipe(catchError(this.handleError));
  }

  CreateTemplateAccess(formData): Observable<boolean> {
    return this.http.post<boolean>(this.appBaseUrl + 'AddTemplateAccess', formData).pipe(catchError(this.handleError));
  }

  DeleteUser(formData): Observable<boolean> {
    return this.http.post<boolean>(this.appBaseUrl + 'DeleteUserWithProductRole', formData).pipe(catchError(this.handleError));
  }

  DeleteUserTemplateAccess(formData): Observable<boolean> {
    return this.http.post<boolean>(this.appBaseUrl + 'DeleteUserTemplateAccess', formData).pipe(catchError(this.handleError));
  }

  DeleteTemplateAccess(formData): Observable<boolean> {
    return this.http.post<boolean>(this.appBaseUrl + 'DeleteTemplateAccess', formData).pipe(catchError(this.handleError));
  }
  UpdateTemplateAccess(formData): Observable<boolean> {
    return this.http.post<boolean>(this.appBaseUrl + 'UpdateTemplateAccess', formData).pipe(catchError(this.handleError));
  }

  private downloadExcelFile(type, url, params, name): Observable<Object[]> {
    name = name + ' ' + this.currentDate + '.xlsx';
    return Observable.create(observer => {

      const xhr = new XMLHttpRequest();

      xhr.open(type, url); xhr.setRequestHeader('Content-type', 'application/json');
      //xhr.setRequestHeader('Authorization', `Bearer ${JSON.parse(localStorage.getItem('currentUser'))["access_token"]}`);
      xhr.responseType = 'blob';

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {

            const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            const blob = new Blob([xhr.response], { type: contentType });
            observer.next(blob);
            observer.complete();
            FileSaver.saveAs(blob, name);
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.send(params);
    });
  }

  private handleError(error: HttpErrorResponse) {
    console.error('server error:', error);
    if (error.error instanceof Error) {
      const errMessage = error.error.message;
      return throwError(errMessage);
      // Use the following instead if using lite-server
      //return Observable.throw(err.text() || 'backend server error');
    }
    return throwError(error || 'Server error');
  }

}
