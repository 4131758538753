import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-user-product-role',
  templateUrl: './user-product-role.component.html',
  styleUrls: ['./user-product-role.component.css']
})
export class UserProductRoleComponent implements OnInit {

  constructor(private dataService: DataService) { }

  productFamilies: any[];
  selectedFamilyId: Number;
  ngOnInit() {
    this.getProducts();
  }
  getProducts() {
    this.dataService.getAllProductFamiliesForAdmins().subscribe(data => this.productFamilies = data);
    }
}
