import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { PreAllocationComponent } from './pre-allocation/pre-allocation.component';
import { DataService } from '../services/data.service';
import { CreateTableComponent } from './create-table/create-table.component';
import { HomeComponent } from './home/home.component';
import { UserProductRoleComponent } from './user-product-role/user-product-role.component';
import { UserProductRoleDisplayComponent } from './user-product-role-display/user-product-role-display.component';
import { TriggerProductComponent } from './trigger-product/trigger-product.component';
import {TriggerProductTablesDisplayComponent} from './trigger-product-tables-display/trigger-product-tables-display.component';
import { TemplateAccessComponent } from './template-access/template-access.component';
import { TemplateAccessUsersComponent } from './template-access-users/template-access-users.component';
import { TemplateAccessUsersDisplayComponent } from './template-access-users-display/template-access-users-display.component';
import { SupportComponent } from './support/support.component';
import { SupportCreateTicketComponent } from './support-create-ticket/support-create-ticket.component';
import { GenerateScriptComponent } from './generate-script/generate-script.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule
  ],
  declarations: [MainComponent, PreAllocationComponent, CreateTableComponent,
    HomeComponent, UserProductRoleComponent, UserProductRoleDisplayComponent,
     TriggerProductComponent, TriggerProductTablesDisplayComponent, TemplateAccessComponent,
    TemplateAccessUsersComponent, TemplateAccessUsersDisplayComponent, SupportComponent, SupportCreateTicketComponent,
     GenerateScriptComponent],
  providers: [DataService]
})
export class HomeModule { }
