import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './home/main/main.component';
import { PreAllocationComponent } from './home/pre-allocation/pre-allocation.component';
import { CreateTableComponent } from './home/create-table/create-table.component';
import { HomeComponent } from './home/home/home.component';
import { GenerateScriptComponent } from './home/generate-script/generate-script.component';
import { AdalGuard } from 'adal-angular4';
import { SchemaComponent } from './home/schema/schema.component';
import { UserProductRoleComponent } from './home/user-product-role/user-product-role.component';
import { TriggerProductComponent } from './home/trigger-product/trigger-product.component';
import { TemplateAccessComponent } from './home/template-access/template-access.component';
import { TemplateAccessUsersDisplayComponent } from './home/template-access-users-display/template-access-users-display.component';
import { SupportComponent } from './home/support/support.component';
import { SupportCreateTicketComponent } from './home/support-create-ticket/support-create-ticket.component';

const routes: Routes = [
  {path: 'create/table', component: CreateTableComponent, pathMatch: 'full', canActivate: [AdalGuard]},
  {path: 'home/:query', component: MainComponent,  canActivate: [AdalGuard]},
  {path: 'upload/:query', component: HomeComponent,  canActivate: [AdalGuard]},
  {path: 'schema/:query', component: SchemaComponent, canActivate: [AdalGuard]},
  {path: 'useraccess', component: UserProductRoleComponent, canActivate: [AdalGuard]},
  {path: 'generateScript', component: GenerateScriptComponent, canActivate: [AdalGuard]},
  {path: 'templateexplicitaccess', component: TemplateAccessComponent, canActivate: [AdalGuard]},
  {path: 'templateexplicituseraccess/:query', component: TemplateAccessUsersDisplayComponent, canActivate: [AdalGuard]},
  {path: 'manualtrigger', component: TriggerProductComponent, canActivate: [AdalGuard]},
  {path: 'support', component: SupportComponent, canActivate: [AdalGuard]},
  {path: 'create/ticket', component: SupportCreateTicketComponent, canActivate: [AdalGuard]},
  {path: '', pathMatch: 'full', redirectTo: 'upload/manualupload',  canActivate: [AdalGuard] },
  {path: '**', pathMatch: 'full', redirectTo: 'upload/manualupload',  canActivate: [AdalGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
