import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'frequencyMonths'
})
export class FrequencyMonths implements PipeTransform {

    transform(value: any): any {

        if (!value) {
            return '';
        }

        let monthsString: String = '';
        // tslint:disable-next-line:no-bitwise
        if ((value & 1) > 0) {
            monthsString += 'January, ';
        }
    // tslint:disable-next-line:no-bitwise
        if ((value & 2) > 0) {
            monthsString += 'February, ';
        }
// tslint:disable-next-line:no-bitwise
        if ((value & 4) > 0) {
            monthsString += 'March, ';
        }
// tslint:disable-next-line:no-bitwise
        if ((value & 8) > 0) {
            monthsString += 'April, ';
        }
// tslint:disable-next-line:no-bitwise
        if ((value & 16) > 0) {
            monthsString += 'May, ';
        }
// tslint:disable-next-line:no-bitwise
        if ((value & 32) > 0) {
            monthsString += 'June, ';
        }
// tslint:disable-next-line:no-bitwise
        if ((value & 64) > 0) {
            monthsString += 'July, ';
        }
// tslint:disable-next-line:no-bitwise
        if ((value & 128) > 0) {
            monthsString += 'August, ';
        }
// tslint:disable-next-line:no-bitwise
        if ((value & 256) > 0) {
            monthsString += 'September, ';
        }
// tslint:disable-next-line:no-bitwise
        if ((value & 512) > 0) {
            monthsString += 'October, ';
        }
// tslint:disable-next-line:no-bitwise
        if ((value & 1024) > 0) {
            monthsString += 'November, ';
        }
// tslint:disable-next-line:no-bitwise
        if ((value & 2048) > 0) {
            monthsString += 'December';
        }

        return monthsString;
    }
}
