import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ColumnSetting, SmartTableOptions } from 'src/app/shared/maersk-smart-table/maersk-smart-table.model';
import { MaerskFinDataVar } from 'src/app/shared/interfaces';
import { DataService } from 'src/app/services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSettings } from 'src/app/appSettings';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExcelService } from 'src/app/services/excel.service';
import { FormGroup,FormControl  } from '@angular/forms';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  fileExt = 'XLS, XLSX';
  maxFiles = 1;
  maxSize = 100;
  errors: string[];
  param: string;
  progressSuccess = false;
  progressValue = 0;
  progressTimeout = null;
  showSuccess = false;
  showStatusHeader = false;
  showSpinner = false;
  statSuccess = false;
  showStatus = false;
  errorMessages: any[] = new Array();

  showError = false;
  fileUploadStatus = false;
  fileDetails = {
    name: null as string,
    size: null as number
  };
  @ViewChild('file') maerskFileInput;
  @ViewChild('uploadStatusModal') uploadStatusModal;
  @ViewChild('editModal') editModal: ElementRef;
  tableName : string;
  maerskData: any[];

  tableSettings: ColumnSetting[];

  tableOptions: SmartTableOptions;
  autoIncrementColumns: any[] = new Array();

  resetFilters = false;
  tableStyle = 'maerskTable';
  masterData: FormGroup;

  constructor(private dataService: DataService, private route: ActivatedRoute, private router: Router, private modalService: NgbModal, private excelService: ExcelService) {}

  ngOnInit() {
    this.masterData = new FormGroup({
      tableId: new FormControl(''),  
      tableName: new FormControl(''),
      description: new FormControl(''),
      bussinessOwner: new FormControl(''),
      familyId: new FormControl('')
     
    });

    this.route.params.subscribe(params => {
      const paramVar = params['query'];
      this.param = paramVar;
      if (this.param === 'Reference') {
        var isValidUser = false;
        var roles = sessionStorage.getItem("Roles")
        var rolesArray =[] ;
        rolesArray = JSON.parse(roles);

        rolesArray.forEach(element => {
          if (element.role == "SUPER_ADMIN"
            || element.role == "PRODUCT_ADMIN") {
              isValidUser = true;
          }
        });
        this.tableOptions = AppSettings.tabSettings[this.param].tableOptions;
      
       if (isValidUser) {
          AppSettings.tabSettings[this.param].tableOptions.edit = true;
       }
        else {
         AppSettings.tabSettings[this.param].tableOptions.edit = false;
        }
         this.tableSettings = AppSettings.tabSettings[this.param].tableSettings;
        this.tableStyle = 'maerskTable';
      } else {
        this.tableOptions = AppSettings.defaultTabSettings.tableOptions;
        this.tableStyle = 'maerskTableSecondary';
      }
      this.getData(this.param);
    });
  }

  getData(query) {

    if (this.param === 'Reference') {
      this.showSpinner = true;
      this.dataService.getMasterDataTablesWithProduct().subscribe(
        data => {
          this.maerskData = data;
          this.showSpinner = false;
      });
    } else {
      this.showSpinner = true;
      this.dataService.getTableData(query).subscribe(data => {
        data.metadata.forEach((metItem)=>{
          if(metItem.autoIncrement){
            this.autoIncrementColumns.push(metItem.columnName);
          }
        })
       data.data=data.data.sort((a,b)=>{return a.ID-b.ID;});
      this.tableSettings = data.metadata.filter((metItem) =>
          metItem.columnVisibility === true );
          this.maerskData = data.data;
          this.showSpinner = false;
        }, error => {
          this.showSpinner = false;
          alert('unable to get data');
        });
    }
  }

  actionRaised(paramArray) {
    let action: number = paramArray[0];
    let userObj = paramArray[1];
    if (action === 1) {
    if (this.param === 'Reference') {
      this.router.navigateByUrl('/home/' + paramArray[1].tableName);
    }
    } else if (action === 3) {
      this.showStatusHeader = false;
    const files = paramArray[1];
    let sizeInMB = files[0].size / (1024 * 1000);
    sizeInMB = Math.round(sizeInMB * 100) / 100; // convert upto 2 decimal place
    this.fileDetails.name = files[0].name;
    this.fileDetails.size = sizeInMB;
    this.saveFiles(files, function(status) {
      // if (status === 'success') {
      //   this.fileUploadStatus = true;
      // } else {
      //   this.fileUploadStatus = false;
      // }
    });
    this.fileUploadStatus = true;
    } else if (action === 5) {
      this.showSpinner = true;
      const autoIncrementColumn = [];
      this.dataService.getTableData(paramArray[1].tableName).subscribe(data => {
        data.metadata.forEach((metaItem) =>    {
          if (metaItem.autoIncrement) {
            autoIncrementColumn.push(metaItem.columnName);
          }
        });
        if (data.data && data.data.length > 0) {
          data.data= data.data.sort((a,b)=>{ return a.ID-b.ID});
          data.data.forEach((metItem) =>    {
            delete metItem["__USER_ID"];
            delete metItem["__TIMESTAMP"];
            autoIncrementColumn.forEach((incrementCol) => {
              delete metItem[incrementCol];
            });  
        });   
          this.excelService.exportAsExcelFile(data.data, 'Loadnow_' + paramArray[1].tableName);
        } else {
          const dataArray = [];
          const dataObject = {};
          data.metadata.forEach((metItem) => {
            dataObject[metItem.columnName] = null;
          });
          dataArray.push(dataObject);
          this.excelService.exportAsExcelFile(dataArray, 'Loadnow_' + paramArray[1].tableName);
        }
        this.showSpinner = false;

        }, error => {
          this.showSpinner = false;
          alert('unable to get data');
        });
    } else if (action === 6) {
      this.router.navigateByUrl('/create/table');
    }
    else if (action === 7) {
      this.masterData.controls.tableId.setValue(userObj.tableId);
      this.masterData.controls.description.setValue(userObj.description);
      this.masterData.controls.bussinessOwner.setValue(userObj.businessOwner);
      this.masterData.controls.familyId.setValue(userObj.familyId);
      this.tableName = userObj.tableName;
      this.modalService.open(this.editModal);
    }
  }

  private isValidFiles(files) {
    // Check Number of files
    if (files.length > this.maxFiles) {
      this.showStatus = true;
      const statusMessage = 'Error: At a time you can upload only ' + this.maxFiles + ' files';
      this.errorMessages.push({message: '', statusMessage: statusMessage});
      return false;
    }
    this.isValidFileExtension(files);
    return this.errorMessages.length === 0;
  }
  private isValidFileExtension(files) {
    // Make array of file extensions
    const extensions = (this.fileExt.split(','))
      .map(function (x) { return x.toLocaleUpperCase().trim(); });
    for (let i = 0; i < files.length; i++) {
      // Get file extension
      const ext = files[i].name.toUpperCase().split('.').pop() || files[i].name;
      // Check the extension exists
      const exists = extensions.includes(ext);
      if (!exists) {
        this.showStatus = true;
        this.showStatusHeader = true;
        const statusMessage = 'Please upload a valid file format: ' + files[i].name;
        this.errorMessages.push({message: '', statusMessage: statusMessage});
      } else {
      // Check file size
      this.isValidFileSize(files[i]);
      }
    }
  }

  private isValidFileSize(file) {
    const fileSizeinMB = file.size / (1024 * 1000);
    const size = Math.round(fileSizeinMB * 100) / 100; // convert upto 2 decimal place
    if (size > this.maxSize) {
      this.showStatus = true;
      const statusMessage = 'Error (File Size): ' + file.name + ': exceed file size limit of'  + this.maxSize + 'MB ( ' + size + 'MB )';
      this.errorMessages.push({message: 'FAILURE', statusMessage: statusMessage});
    }
  }

  onFileChange(event) {

    this.showStatusHeader = false;
    const files = event.target.files;
    let sizeInMB = files[0].size / (1024 * 1000);
    sizeInMB = Math.round(sizeInMB * 100) / 100; // convert upto 2 decimal place
    this.fileDetails.name = files[0].name;
    this.fileDetails.size = sizeInMB;
    this.saveFiles(files, function(status) {
      // if (status === 'success') {
      //   this.fileUploadStatus = true;
      // } else {
      //   this.fileUploadStatus = false;
      // }
    });
    this.fileUploadStatus = true;
    this.maerskFileInput.nativeElement.value = '';
  }

  private updateProgressSuccess() {
    this.showError = false;
    this.showSuccess = true;
    this.progressValue = 100;
  }

  private updateProgressError() {
    this.showSuccess = false;
    this.showError = true;
    this.progressValue = 100;
  }

  clearErrorMessages() {
    this.errorMessages = new Array();
  }

  saveFiles(files, callback) {
    this.clearErrorMessages();
    this.OpenUploadStatus(this.uploadStatusModal);
    if (this.param) {
      this.showStatus = true;
    this.progressValue = 0;
    this.showError = false;
    this.showSuccess = false;
    this.progressTimeout = setInterval(() => {
      if (this.progressValue <= 80) {
        this.progressValue += 10;
      }
    }, 1000);
    this.errors = []; // Clear error
    // Validate file size and allowed extensions
    if (files.length > 0 && (!this.isValidFiles(files))) {
      clearInterval(this.progressTimeout);
      this.updateProgressError();
      return;
    }
    if (files.length > 0) {
      const formData: FormData = new FormData();
      for (let j = 0; j < files.length; j++) {
        formData.append('file', files[j], files[j].name);
      }
      formData.append('typeOfFile', this.param);
      this.errorMessages.push({message: '', statusMessage: 'File is being Uploaded. Please Wait...'});
      this.dataService.UploadFile(formData)
        .subscribe(
        success => {
          this.showStatus = true;
          if (success.message === true) {
            this.clearErrorMessages();
            this.showStatusHeader = true;
            this.errorMessages.push({message: '', statusMessage: 'File validated and Uploaded Successfully'});
            this.statSuccess = true;
            this.getData(this.param);
          } else {
            this.clearErrorMessages();
            this.showStatusHeader = true;
          this.errorMessages.push({message: '', statusMessage: success.statusMessage});
          this.statSuccess = false;
          }
          this.updateProgressSuccess();
          clearInterval(this.progressTimeout);
          setTimeout(() => {
            this.progressTimeout = null;
          }, 10000);
          callback('success');
        },
        error => {
          this.updateProgressError();
          clearInterval(this.progressTimeout);
          this.showStatus = true;
          this.statSuccess = false;
          this.clearErrorMessages();
          this.errorMessages.push({message: '', statusMessage: JSON.stringify(error)});
          callback('error');
        });
    }
  } else {
    this.showStatus = true;
    this.showStatusHeader = true;
    this.errorMessages.push({message: 'FAILED', statusMessage: 'Please select the Template before uploading the file and upload the file'});
   }
  }

  OpenUploadStatus(uploadStatModal) {
    this.modalService.open(uploadStatModal);
  }

    onSubmit(masterDataObj) {
      this.dataService.updateMasterDataTablesWithProduct(masterDataObj.value).subscribe(data => {
        if (data['status']) {
          alert(data['statusMessage']);
          
        } else {
          alert(data['statusMessage']);
        }
        this.getData("Reference");
        
      }, error => {
        alert('Master data cannot be updated');
      });
      this.modalService.dismissAll();
    }

}
