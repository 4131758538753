import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, AfterViewChecked, ChangeDetectorRef, ElementRef, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { ColumnSetting, SmartTableOptions } from './maersk-smart-table.model';
import { SimpleChange } from '@angular/core/src/change_detection/change_detection_util';
import { forEach } from '@angular/router/src/utils/collection';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExcelService } from 'src/app/services/excel.service';

@Component({
  selector: 'app-maersk-smart-table',
  templateUrl: './maersk-smart-table.component.html',
  styleUrls: ['./maersk-smart-table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaerskSmartTableComponent implements OnInit, OnChanges, AfterViewChecked {
  currentIterationList = new Array();
  currentSelectedFilter = new Array();
  maxDistinctVar: number = 0;
  showStatusHeader = false;
  checkBox: boolean;
  defaultRowsManual: number;
  selectChecked: boolean = false;
  selectAllVar: boolean = false;
  selectPageVar: boolean = false;
  @ViewChild('file') maerskFileInput;
  deleteObj: {};
  private mainRecords = new Array();
  private updateIterationSelection: boolean = false;
  hoveredIndex: number;//Variable for assigning onHover effect
  filteredRecords = new Array();//Contains the records after filter which is used for rendering
  filterCounter: number;//Contains the counts for filter
  filterStatus: boolean = false;//Filter Status
  fileArray = new Array();
  @Input() styleID: string;
  @Input() options: SmartTableOptions;
  @Input() public records = new Array();//Total Number of records
  @Input() caption: string;
  @Input() settings: ColumnSetting[];//Settings for column like format, currency etc.
  @Input() defaultRows: number = 5;
  @Output() actionChange: EventEmitter<number[]> = new EventEmitter();
  @Input() addButtonText: string;
  @Input() templateName: string;
  @Input() autoIncrementColumns: any[] = new Array();

  resetFilters: boolean = false;
  //@Input() refreshFilter: boolean = false;
  keys: string[];
  columnMaps: ColumnSetting[];
  
  constructor(private cdr: ChangeDetectorRef, private modalService: NgbModal, private elRef: ElementRef, private excelService: ExcelService) { }

  ngAfterViewChecked() {
    //your code to update the model
    this.cdr.detectChanges();
  }

  ngOnInit() {
    this.defaultRowsManual = this.defaultRows;
    this.mainRecords = this.records;
    if (this.records != undefined) {
      this.records.forEach((element) => {
        element.checked = false;
      });
      this.filteredRecords = this.records;
      this.currentIterationList = this.records;
    } else {
      this.filteredRecords = new Array();
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('resetFilters') || changes.previousValue == undefined) {
      this.fileArray = [];
      this.filteredRecords = this.records;
      if (this.records != undefined) {
        this.records.forEach((element) => {
          element.checked = false;
        });
        this.filteredRecords = this.records;
        this.currentIterationList = this.records;
      } else {
        this.filteredRecords = new Array();
      }
      if (this.columnMaps) {
        this.columnMaps.forEach((item) => {
          for (let _i = 0; _i <= this.maxDistinctVar; _i++) {
            const element = <HTMLInputElement>document.getElementById(item.columnName + _i);
            if (element) {
              element.checked = false;
            }
          }
        });
      }
    }
    if (this.settings) { // when settings provided
      this.columnMaps = this.settings;
     } else { // no settings, create column maps with defaults
      this.columnMaps = Object.keys(this.records[0])
        .map(key => {
          return {
            columnName: key,
            columnAlias: key.slice(0, 1).toUpperCase() +
              key.replace(/_/g, ' ').slice(1)
          };
        });
    }
  }
  isEdit(record) {
    
    var isValidUser = false;
        var roles = sessionStorage.getItem("Roles")
        var rolesArray =[] ;
        rolesArray = JSON.parse(roles);

        if (this.templateName == 'Reference') {
          rolesArray.forEach(element => {
            if (element.role == 'SUPER_ADMIN'
              || (element.role == 'PRODUCT_ADMIN'
              && element.productId == record.familyId)) {
                isValidUser = true;
            }
          });
        }
        else {
          rolesArray.forEach(element => {
            if (element.role == 'SUPER_ADMIN'
              || element.role == 'PRODUCT_ADMIN') {
                isValidUser = true;
            }
          });
        }
        return isValidUser;
  }
  containsObject(obj, list) {
    let i;
    let listLength = list.length;
    for (i = 0; i < listLength; i++) {
      if (list[i] === obj) {
        return true;
      }
    }
    return false;
  }
  //sorting
  sortedKey: string; //set default
  reverse: boolean = false;
  sort(key) {
    this.sortedKey = key;
    this.reverse = !this.reverse;
  }

  //initializing pagination variable to one
  p: number = 1;

  downloadAllRecordsAsExcel() {
    if (this.records && this.records.length > 0) {
      this.records.forEach(js => {
        delete js["__USER_ID"];
        delete js["__TIMESTAMP"];

        this.autoIncrementColumns.forEach((col)=>{
          delete js[col];
        });

      });
      this.excelService.exportAsExcelFile(this.records.map(({checked, ...rest}) => rest), 'Loadnow_' + this.templateName);
    } else {
      const dataArray = [];
      const dataObject = {};
      this.columnMaps.forEach((cm) => {
        dataObject[cm.columnName] = null;
      });
      dataArray.push(dataObject);
      this.excelService.exportAsExcelFile(dataArray, 'Loadnow_' + this.templateName);
    }
  }

  resetAllFilters() {
    this.resetFilters = !this.resetFilters;
    this.fileArray = [];
      this.filteredRecords = this.records;
      if (this.records != undefined) {
        this.records.forEach((element) => {
          element.checked = false;
        });
        this.filteredRecords = this.records;
        this.currentIterationList = this.records;
      } else {
        this.filteredRecords = new Array();
      }
      if (this.columnMaps) {
        this.columnMaps.forEach((item) => {
          for (let _i = 0; _i <= this.maxDistinctVar; _i++) {
            const element = <HTMLInputElement>document.getElementById(item.columnName + _i);
            if (element) {
              element.checked = false;
            }
          }
        });
      }
  }

  //Distinct Values in column names based for multi select functionality
  selectDistinct(key) { 
    let currentIterationList = this.currentIterationList;
    let lookup = [];
    let result = [];
    if (this.currentSelectedFilter.length) {
      this.currentSelectedFilter.forEach(function (item1) {
        if (item1.Field == key) {
          result = item1.Values;
        }
      });
    }

    if (result.length) {
      return result;
    }
    let length = currentIterationList.length || 0;

    for (let i = 0; i < length; i++) {
      if (lookup[currentIterationList[i][key]]) continue;
      lookup[currentIterationList[i][key]] = true;
      result.push(currentIterationList[i][key]);
    }
    result.sort(function (a, b) { return (a - b) });
    if (this.maxDistinctVar < result.length) {
      this.maxDistinctVar = result.length;
    }

    if (this.fileArray.length) {
      let addToList = false;
      this.fileArray.forEach((item2) => {
        if (item2.Field == key) {
          addToList = true;
        }
      });
      if (addToList) {
        this.currentSelectedFilter.forEach((item3) => {
          if (item3.Field == key) {
            addToList = false;
          }
        });
        if (addToList) {
          let newObj = {
            "Field": key,
            "Values": result
          }
          this.currentSelectedFilter.push(newObj);
        }
        this.currentIterationList = this.filteredRecords;
      }
    } else {
      this.currentIterationList = this.records;
      this.currentSelectedFilter = [];
    }
    return result;
  }

  //MultiSelect Functionality
  // multiSelectFn(columnName, value, $event) {
  //   let filterObj = { columnName: value };
  //   if ($event.target.checked) {
  //     if (!this.filterStatus) {
  //       this.filteredRecords = [];
  //       this.filterStatus = true;
  //     }
  //     let length = this.records.length;
  //     for (let i = 0; i < length; i++) {
  //       if (this.records[i][columnName] !== value) continue;
  //       if (!this.containsObject(this.records[i], this.filteredRecords)) {
  //         this.filteredRecords.push(this.records[i]);
  //       }
  //     }
  //   } else {
  //     let length = this.filteredRecords.length;
  //     for (let i = 0; i < length; i++) {
  //       if (this.filteredRecords[i][columnName] !== value) continue;
  //       this.filteredRecords.splice(i, 1);
  //       i--;
  //       length--;
  //     }
  //   }
  //   if (this.filteredRecords.length === 0) {
  //     this.filteredRecords = this.records;
  //     this.filterStatus = false;
  //   }
  // }

  deleteRecord(record, deleteModal) {
    this.deleteObj = record;
    this.modalService.open(deleteModal);
  }

  multiSelectFn(columnName, value, $event) {
    // console.log(columnName);
    // console.log(value);
    // console.log($event);

    if ($event.target.checked) {
      let status = false;
      this.fileArray.forEach((element) => {
        if (element['Field'] === columnName) {
          element['Values'].push(value);
          status = true;
          this.updateIterationSelection = true;
        }
      });
      if (!status) {
        let tempObj = {};
        tempObj['Field'] = columnName;
        tempObj['Values'] = [];
        tempObj['Values'].push(value);
        this.fileArray.push(tempObj);
      }
    } else {
      this.fileArray.forEach((element, index, array) => {
        if (element.Field === columnName) {
          let newIndex = element.Values.indexOf(value);
          if (index >= 0) {
            element.Values.splice(newIndex, 1)
          }
          if (element.Values.length === 0) {
            this.currentSelectedFilter.forEach((element1, index1, array1) => {
              if (element.Field === element1.Field) {
                array1.splice(index1, 1);
              }
            });
            array.splice(index, 1);
          }
        }
      })
      this.updateIterationSelection = true;
    }
    this.filterArray();
  }
  groupSelect() {
    if (this.selectAllVar) {
      if (this.selectChecked) {
        this.filteredRecords.forEach((element) => {
          element.checked = true;
        })
      } else {
        this.filteredRecords.forEach((element) => {
          element.checked = false;
        })
      }
    } else if (this.selectPageVar) {
      let firstIndex = this.p * this.defaultRows - this.defaultRows;
      let lastIndex = this.p * this.defaultRows;
      this.filteredRecords.forEach((element, index) => {
        if (index >= firstIndex && index < lastIndex) {
          element.checked = true;
        }
      })
    } else {
      this.filteredRecords.forEach((element) => {
        element.checked = false;
      })
    }
  }
  // filterCallBack(element, index, array) {
  //   let count = 0;
  //   for (let n = 0; n < this.fileArray.length; n++) {
  //     if (this.fileArray[n]["Values"].indexOf(element[this.fileArray[n]["Field"]]) > -1) {
  //       count++;
  //     }
  //   }
  //   return count == this.fileArray.length;
  // }
  // consolidatedFilters(passedArray, passedFilter) {
  //   let filteredArray = [];
  //   passedArray.forEach(function (elem) {
  //     Object.keys(passedFilter).every(function (k) {
  //       if (elem[k] === passedFilter[k]) {
  //         filteredArray.push(elem);
  //         return true
  //       }
  //     })
  //   })
  // }
  // createArrayLine(fileArray) {
  //   let newString: string = "";
  //   for (let property in fileArray) {
  //     if (fileArray.hasOwnProperty(property)) {
  //       fileArray[property].forEach((element) => {
  //         if (newString.length) {
  //           newString += " && obj['" + property + "'] == '" + element + "'";
  //         } else {
  //           newString = "obj['" + property + "'] == '" + element + "'";
  //         }
  //       });
  //     }
  //   }
  //   return newString;
  // }
  //Action Button Functionality
  actionCall(func, record) { //func for type of button, id for API
    let array = [];
    array.push(func);
    array.push(record);
    this.actionChange.emit(array);
    if ( func === 4 ) {
      this.modalService.dismissAll();
    }
  }
  filterArray() {
    let counter = 0;
    this.filteredRecords = this.records;
    this.filteredRecords = this.filteredRecords.filter((element) => {
      let count = 0;
      for (let n = 0; n < this.fileArray.length; n++) {
        if (this.fileArray[n]["Values"].indexOf(element[this.fileArray[n]["Field"]]) > -1) {
          count++;
        }
      }
      return count == this.fileArray.length;
    });
    if (this.updateIterationSelection) {
      this.currentIterationList = this.filteredRecords;
      this.updateIterationSelection = false;
    }
  }

  preventDropdownClose() {
    event.stopPropagation();
  }

  onFileChange(event) {

    this.showStatusHeader = false;
    const files = event.target.files;
    this.actionCall(3, files);
    this.maerskFileInput.nativeElement.value = '';
  }
}


