import { Component, OnInit, Input, OnChanges, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';
import { ColumnSetting, SmartTableOptions } from 'src/app/shared/maersk-smart-table/maersk-smart-table.model';
import { AppSettings } from 'src/app/appSettings';
import { DataService } from 'src/app/services/data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-template-access-users',
  templateUrl: './template-access-users.component.html',
  styleUrls: ['./template-access-users.component.css']
})
export class TemplateAccessUsersComponent implements OnInit {

  @Input() templateId: Number;
  @Input() productFamilies: any[];

  templateAccess: FormGroup;

  tableSettings: ColumnSetting[];
  maerskData: any[];
  roles: any[];
  tableOptions: SmartTableOptions;
  @ViewChild('addModal') addPopup: ElementRef;
  tables: any[];
  isAdd: boolean;
  days = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31
  ];

  months = [{
    'key': 'Jan',
    'value': 1
  },
  {
    'key': 'Feb',
    'value': 2
  },
  {
    'key': 'Mar',
    'value': 4
  },
  {
    'key': 'Apr',
    'value': 8
  },
  {
    'key': 'May',
    'value': 16
  },
  {
    'key': 'Jun',
    'value': 32
  },
  {
    'key': 'Jul',
    'value': 64
  },
  {
    'key': 'Aug',
    'value': 128
  },
  {
    'key': 'Sep',
    'value': 256
  },
  {
    'key': 'Oct',
    'value': 512
  },
  {
    'key': 'Nov',
    'value': 1024
  },
  {
    'key': 'Dec',
    'value': 2048
  }
];


  constructor(private dataService: DataService, private route: ActivatedRoute, private router: Router, private modalService: NgbModal) { }

  ngOnInit() {
    this.templateAccess = new FormGroup({
      id: new FormControl(''), 
      tableId: new FormControl(''),  
      accessType: new FormControl(''),
      frequencyMonths: new FormControl(''),
      startDay: new FormControl(''),
      endDay: new FormControl(''),
      selectedMonths: new FormControl('')
    });
    this.tableOptions = AppSettings.tabSettings['TemplateAccess'].tableOptions;
      this.tableSettings = AppSettings.tabSettings['TemplateAccess'].tableSettings;
      this.getData();
  }

  ngOnChanges() {
    if (this.templateId) {
      this.getData();
    }
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   const prId: SimpleChange = changes.productId;
  //   console.log('prev value: ', name.previousValue);
  //   console.log('got name: ', name.currentValue);
  //   this._name = name.currentValue.toUpperCase();
  // }

  OpenAddUser(addModal) {
    this.modalService.open(addModal);
  }
 
  
  getData() {
    if (this.templateId) {
    this.dataService.getTemplateAccessDataForTableId(this.templateId.toString()).subscribe(data => {
      this.maerskData = data;
    });
  }
  this.dataService.getExplicitAccessTablesWithProduct().subscribe(accessTablesData => {
    this.tables = accessTablesData;
  });
  }

  onSubmit(templateAccessObj) {

    if(this.isAdd) {
      if (templateAccessObj.value.selectedMonths.length > 0) {
        let sum = 0;
        templateAccessObj.value.selectedMonths.forEach(monthValue => {
          // tslint:disable-next-line:radix
          const vlue: number =  parseInt(monthValue);
          sum = sum + vlue;
        });
        templateAccessObj.value.frequencyMonths = sum;
      }
      this.dataService.CreateTemplateAccess(templateAccessObj.value).subscribe(success => {
        this.getData();
      }, error => {
        alert('User could not be added');
      });
      this.modalService.dismissAll();
    } 
    else {
      if (templateAccessObj.value.selectedMonths.length > 0) {
        let sum = 0;
        templateAccessObj.value.selectedMonths.forEach(monthValue => {
          // tslint:disable-next-line:radix
          const vlue: number =  parseInt(monthValue);
          sum = sum + vlue;
        });
        templateAccessObj.value.frequencyMonths = sum;
      }
      this.dataService.UpdateTemplateAccess(templateAccessObj.value).subscribe(success => {
        this.getData();
      }, error => {
        alert("Template could not be updated");
      
      });
      this.modalService.dismissAll();
    }
  }

  actionRaised(func) {
    let userObj = func[1];
    let action: number = func[0];
    //1- Add User 4-Delete
    if (action === 1) {
      this.isAdd = true;
      this.router.navigateByUrl('/templateexplicituseraccess/' + func[1].id);
    }
    else if (action === 7) {
      this.isAdd = false;
      this.templateAccess.controls.tableId.setValue(userObj.tableId);
      this.templateAccess.controls.id.setValue(userObj.id);
      //this.templateAccess.get('tableId').disable();
      this.templateAccess.controls.accessType.setValue(userObj.accessType);
      this.templateAccess.controls.startDay.setValue(userObj.startDay);
      this.templateAccess.controls.endDay.setValue(userObj.endDay);

      let tempMonthArray=[];
      if((1&userObj.frequencyMonths)>0){
        tempMonthArray.push('1');
      }
      if((2&userObj.frequencyMonths)>0){
        tempMonthArray.push('2');
      }
      if((4&userObj.frequencyMonths)>0){
        tempMonthArray.push('4');
      }
      if((8&userObj.frequencyMonths)>0){
        tempMonthArray.push('8');
      }
      if((16&userObj.frequencyMonths)>0){
        tempMonthArray.push('16');
      }
      if((32&userObj.frequencyMonths)>0){
        tempMonthArray.push('32');
      }
      if((64&userObj.frequencyMonths)>0){
        tempMonthArray.push('64');
      }
      if((128&userObj.frequencyMonths)>0){
        tempMonthArray.push('128');
      }
      if((256&userObj.frequencyMonths)>0){
        tempMonthArray.push('256');
      }
      if((512&userObj.frequencyMonths)>0){
        tempMonthArray.push('512');
      }
      if((1024&userObj.frequencyMonths)>0){
        tempMonthArray.push('1024');
      }
      if((2048&userObj.frequencyMonths)>0){
        tempMonthArray.push('2048');
      }

      this.templateAccess.controls.selectedMonths.setValue(tempMonthArray);

      this.OpenAddUser(this.addPopup);
      //this.dataService.UpdateTemplateAccess(userObj)
    }
    else if (action === 4) {
      this.dataService.DeleteTemplateAccess(userObj)
        .subscribe(
        result => {
          console.log('Delete Successful');
        },
        error => {
          console.log('Delete failed');
        },
        () => {
          this.getData();
        }
        );
    } else if (action === 6) {
      this.isAdd = true;
      this.templateAccess.controls.tableId.setValue(null);
      this.templateAccess.controls.id.setValue(null);
      this.templateAccess.controls.accessType.setValue(null);
      this.templateAccess.controls.startDay.setValue(null);
      this.templateAccess.controls.endDay.setValue(null);
      this.templateAccess.controls.selectedMonths.setValue(null);
      this.templateAccess.get('tableId').enable();
    this.OpenAddUser(this.addPopup);
  }
  }
}
