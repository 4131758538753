import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdalService } from 'adal-angular4';

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.css']
})
export class MenuBarComponent implements OnInit {

  firstName: string;
  returnUrl: string;
  userAuthenticated: boolean = false;
  isValidUser = false;

  constructor(
    private route: ActivatedRoute,
    private adalService: AdalService
  ) { }

  ngOnInit() {
    if (this.adalService.userInfo.authenticated) {
      this.adalService.getUser().subscribe(data => {
        this.userAuthenticated = true;
        this.firstName = data.userName;
      });
      var roles = sessionStorage.getItem("Roles")
      var rolesArray =[] ;
      rolesArray = JSON.parse(roles);

      rolesArray.forEach(element => {
        if (element.role == "SUPER_ADMIN"
        || element.role == "PRODUCT_ADMIN") {
          this.isValidUser = true;
        }
      });
    }
  }

  public onHidden(): void {
    console.log('Dropdown is hidden');
  }
  public onShown(): void {
    console.log('Dropdown is shown');
  }
  public isOpenChange(): void {
    console.log('Dropdown state is changed');
  }

  public LogOut(): void {
    console.log('logout clicked');
    this.adalService.logOut();
    this.userAuthenticated = false;
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  public LogIn(): void {
    this.adalService.login();
  }

}
