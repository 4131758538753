import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AdalService } from 'adal-angular4';
import { mergeMap } from 'rxjs/operators';


@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
    constructor(private adalService: AdalService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const jwt = this.adalService.userInfo.token;
    if (!!jwt) {
     request = request.clone({
       setHeaders: {
        'Authorization': `Bearer ${jwt}`
       }
     });
   }
   return next.handle(request);
    }
}
