import { Component, OnInit } from '@angular/core';
import { ColumnSetting, SmartTableOptions } from 'src/app/shared/maersk-smart-table/maersk-smart-table.model';
import { PreAllocationData } from 'src/app/shared/interfaces';

@Component({
  selector: 'app-pre-allocation',
  templateUrl: './pre-allocation.component.html',
  styleUrls: ['./pre-allocation.component.css']
})
export class PreAllocationComponent implements OnInit {

  constructor() { }

  showSpinner = false;
  preAllocationData: PreAllocationData[];

  tableSettings: ColumnSetting[] = [{
    columnName: 'reportingPeriod',
    columnAlias: 'REPORTING_PERIOD'
  },
  {
    columnName: 'sapAccount',
    columnAlias: 'SAP_ACCOUNT'
  },
  {
    columnName: 'material',
    columnAlias: 'MATERIAL'
  },
  {
    columnName: 'pnlLine',
    columnAlias: 'PNL_LINE'
  },
  {
    columnName: 'allocPnlLine',
    columnAlias: 'ALLOC_PNL_LINE'
  },
  {
    columnName: 'vendorId',
    columnAlias: 'VENDOR_ID'
  },
  {
    columnName: 'centre',
    columnAlias: 'CENTRE'
  },
  {
    columnName: 'location',
    columnAlias: 'LOCATION'
  },
  {
    columnName: 'location5',
    columnAlias: 'LOCATION5'
  },
  {
    columnName: 'vendorCtrCd',
    columnAlias: 'VENDOR_CTR_CD'
  },
  {
    columnName: 'locCtrCd',
    columnAlias: 'LOC_CTR_CD'
  },
  {
    columnName: 'corridorLoc1',
    columnAlias: 'CORRIDOR_LOC_1'
  },
  {
    columnName: 'corridorLoc2',
    columnAlias: 'CORRIDOR_LOC_2'
  },
  {
    columnName: 'accountingPeriod',
    columnAlias: 'ACCOUNTING_PERIOD'
  },
  {
    columnName: 'activityPeriod',
    columnAlias: 'ACTIVITY_PERIOD'
  },
  {
    columnName: 'vessel',
    columnAlias: 'VESSEL'
  },
  {
    columnName: 'voyage',
    columnAlias: 'VOYAGE'
  },
  {
    columnName: 'orgVoyage',
    columnAlias: 'ORG_VOYAGE'
  },
  {
    columnName: 'service',
    columnAlias: 'SERVICE'
  },
  {
    columnName: 'fromLocation',
    columnAlias: 'FROM_LOCATION'
  },
  {
    columnName: 'fromZone',
    columnAlias: 'FROM_ZONE'
  },
  {
    columnName: 'toLocation',
    columnAlias: 'TO_LOCATION'
  },
  {
    columnName: 'toZone',
    columnAlias: 'TO_ZONE'
  },
  {
    columnName: 'contSize',
    columnAlias: 'CONT_SIZE'
  },
  {
    columnName: 'sapContSize',
    columnAlias: 'SAP_CONT_SIZE'
  },
  {
    columnName: 'opContSize',
    columnAlias: 'OP_CONT_SIZE'
  },
  {
    columnName: 'contType',
    columnAlias: 'CONT_TYPE'
  },
  {
    columnName: 'sapContType',
    columnAlias: 'SAP_CONT_TYPE'
  },
  {
    columnName: 'opContType',
    columnAlias: 'OP_CONT_TYPE'
  },
  {
    columnName: 'breakBulkYN',
    columnAlias: 'BREAK_BULK_YN'
  },
  {
    columnName: 'string',
    columnAlias: 'STRING'
  },
  {
    columnName: 'stringCompanyCode',
    columnAlias: 'STRING_COMPANY_CODE'
  },
  {
    columnName: 'stringDirection',
    columnAlias: 'STRING_DIRECTION'
  },
  {
    columnName: 'cargoType',
    columnAlias: 'CARGO_TYPE'
  },
  {
    columnName: 'moveType',
    columnAlias: 'MOVE_TYPE'
  },
  {
    columnName: 'shipmentNo',
    columnAlias: 'SHIPMENT_NO'
  },
  {
    columnName: 'containerNo',
    columnAlias: 'CONTAINER_NO'
  },
  {
    columnName: 'portOfDischarge',
    columnAlias: 'PORT_OF_DISCHARGE'
  },
  {
    columnName: 'portOfLoad',
    columnAlias: 'PORT_OF_LOAD'
  },
  {
    columnName: 'companyCode',
    columnAlias: 'COMPANY_CODE'
  },
  {
    columnName: 'amount',
    columnAlias: 'AMOUNT'
  },
  {
    columnName: 'activityTs',
    columnAlias: 'ACTIVITY_TS'
  },
  {
    columnName: 'allocMove',
    columnAlias: 'ALLOC_MOVE'
  },
  {
    columnName: 'allocatedAmount',
    columnAlias: 'AllocatedAmount'
  },
  {
    columnName: 'sourceAmount',
    columnAlias: 'SourceAmount'
  },
  {
    columnName: 'workSpaceId',
    columnAlias: 'WorkSpaceID'
  },
  {
    columnName: 'ruleId',
    columnAlias: 'RuleID'
  },
  {
    columnName: 'stepId',
    columnAlias: 'StepID'
  },
  {
    columnName: 'previousStepId',
    columnAlias: 'PreviousStepID'
  },
  {
    columnName: 'stepSeqNo',
    columnAlias: 'StepSeqNr'
  },
  {
    columnName: 'driverValue',
    columnAlias: 'DriverValue'
  },
  {
    columnName: 'intermediateResFlag',
    columnAlias: 'IntermedResFlag'
  },
  {
    columnName: 'UnAllocAmountFlag',
    columnAlias: 'UnAllocAmtFlag'
  },
  {
    columnName: 'runId',
    columnAlias: 'RunID'
  },
  {
    columnName: 'portOptionYN',
    columnAlias: 'PORT_OPTION_YN'
  },
  {
    columnName: 'weightRestrictionYN',
    columnAlias: 'WEIGHT_RESTRICTION_YN'
  },
  {
    columnName: 'serviceType',
    columnAlias: 'SERVICE_TYPE'
  },
  {
    columnName: 'ownerString',
    columnAlias: 'OWNER_STRING'
  },
  {
    columnName: 'ownerCompanyCode',
    columnAlias: 'OWNER_COMPANY_CODE'
  },
  {
    columnName: 'onOwnString',
    columnAlias: 'ON_OWN_STRING_YN'
  },
  {
    columnName: 'serviceStatus',
    columnAlias: 'SERVICE_STATUS'
  },
  {
    columnName: 'weight',
    columnAlias: 'WEIGHT'
  },
  {
    columnName: 'liveReeferYN',
    columnAlias: 'LIVE_REEFER_YN'
  },
  {
    columnName: 'serviceCompanyCode',
    columnAlias: 'SERVICE_COMPANY_CODE'
  },
  {
    columnName: 'inPnlLine',
    columnAlias: 'IN_PNL_LINE'
  },
  {
    columnName: 'sourceId',
    columnAlias: 'SOURCE_ID'
  },
  {
    columnName: 'postingDate',
    columnAlias: 'POSTING_DATE'
  },
  {
    columnName: 'freightType',
    columnAlias: 'FREIGHT_TYPE'
  },
  {
    columnName: 'salesOrderDocType',
    columnAlias: 'SALES_ORDER_DOC_TYPE'
  },
  {
    columnName: 'itemCategory',
    columnAlias: 'ITEM_CATEGORY'
  },
  {
    columnName: 'grossRevSource',
    columnAlias: 'GROSS_REV_SOURCE'
  },
  {
    columnName: 'fullEmptySeaLand',
    columnAlias: 'FULL_EMPTY_SEA_LAND'
  },
  {
    columnName: 'porLocation5',
    columnAlias: 'POR_LOCATION5'
  },
  {
    columnName: 'porActivityPeriod',
    columnAlias: 'POR_ACTIVITY_PERIOD'
  },
  {
    columnName: 'podLocation5',
    columnAlias: 'POD_LOCATION5'
  },
  {
    columnName: 'podActivityPeriod',
    columnAlias: 'POD_ACTIVITY_PERIOD'
  },
  {
    columnName: 'pobLocation5',
    columnAlias: 'POB_LOCATION5'
  },
  {
    columnName: 'porPodPob',
    columnAlias: 'PORPODPOB'
  },
  {
    columnName: 'emImportYN',
    columnAlias: 'EM_IMPORT_YN'
  },
  {
    columnName: 'emCostType',
    columnAlias: 'EM_COST_TYPE'
  },
  {
    columnName: 'emSubCostType',
    columnAlias: 'EM_COST_SUB_TYPE'
  },
  {
    columnName: 'contHighYN',
    columnAlias: 'CONT_HIGH_YN'
  },
  {
    columnName: 'vendorCtlOpcl',
    columnAlias: 'VENDOR_CTR_OPCL'
  },
  {
    columnName: 'locCtrOpcl',
    columnAlias: 'LOC_CTR_OPCL'
  },
  {
    columnName: 'emptyYN',
    columnAlias: 'EMPTY_YN'
  },
  {
    columnName: 'operatorCompanyCode',
    columnAlias: 'OPERATOR_COMPANY_CODE'
  },
  {
    columnName: 'emTrade',
    columnAlias: 'EM_TRADE'
  },
  {
    columnName: 'companyGroup',
    columnAlias: 'COMPANY_GROUP'
  },
  {
    columnName: 'bcsCompanyCode',
    columnAlias: 'BCS_COMPANY_CODE'
  },
  {
    columnName: 'headHaulYN',
    columnAlias: 'HEADHAUL_YN'
  },
  {
    columnName: 'transportationMode',
    columnAlias: 'TRANSPORTATION_MODE'
  },
  {
    columnName: 'driverValueABS',
    columnAlias: 'DriverValueABS'
  },
  {
    columnName: 'counterPostYN',
    columnAlias: 'COUNTER_POST_YN'
  },
  {
    columnName: 'finalResultYN',
    columnAlias: 'FINAL_RESULT_YN'
  },
  {
    columnName: 'buyingString',
    columnAlias: 'BUYING_STRING'
  },
  {
    columnName: 'sellingService',
    columnAlias: 'SELLING_SERVICE'
  },
  {
    columnName: 'sellingString',
    columnAlias: 'SELLING_STRING'
  }
];

tableOptions: SmartTableOptions = {
  multiSelect: true,
  sort: false,
  search: true,
  pagination: true,
  action: 1
};

resetFilters = false;


  ngOnInit() {
    this.preAllocationData = [
      {
        reportingPeriod: '2018007',
        pnlLine: 'PnL.VCC.031a',
        allocPnlLine: 'PnL.VCC.031a',
        accountingPeriod: '2018001',
        activityPeriod: '2018001',
        vessel: 'h7b',
        companyCode: 1000,
        amount: 277628,
        allocatedAmount: 277628,
        sourceAmount: 277628,
        intermediateResFlag: false,
        UnAllocAmountFlag: false,
        sourceId: 101,
        companyGroup: 'ML-SCL',
        finalResultYN: false,
        sapAccount: '',
        material: '',
        vendorId: '',
        centre: '',
        location: '',
        location5: '',
        vendorCtrCd: '',
        locCtrCd: '',
        corridorLoc1: '',
        corridorLoc2: '',
        voyage: '',
        orgVoyage: '',
        service: '',
        fromLocation: '',
        fromZone: '',
        toLocation: '',
        toZone: '',
        contSize: '',
        sapContSize: '',
        opContSize: '',
        contType: '',
        sapContType: '',
        opContType: '',
        breakBulkYN: false,
        string: '',
        stringCompanyCode: '',
        stringDirection: '',
        cargoType: '',
        moveType: '',
        shipmentNo: '',
        containerNo: '',
        portOfDischarge: '',
        portOfLoad: '',
        activityTs: '',
        allocMove: '',
        workSpaceId: '',
        ruleId: 0,
        stepId: 0,
        previousStepId: 0,
        stepSeqNo: 0,
        driverValue: '',
        runId: 0,
        portOptionYN: false,
        weightRestrictionYN: false,
        serviceType: '',
        ownerString: '',
        ownerCompanyCode: '',
        onOwnString: false,
        serviceStatus: '',
        weight: 0,
        liveReeferYN: false,
        serviceCompanyCode: '',
        inPnlLine: '',
        postingDate: '',
        freightType: '',
        salesOrderDocType: '',
        itemCategory: '',
        grossRevSource: '',
        fullEmptySeaLand: '',
        porLocation5: '',
        porActivityPeriod: '',
        podLocation5: '',
        podActivityPeriod: '',
        pobLocation5: '',
        porPodPob: '',
        emImportYN: false,
        emCostType: '',
        emSubCostType: '',
        contHighYN: false,
        vendorCtlOpcl: '',
        locCtrOpcl: '',
        emptyYN: false,
        operatorCompanyCode: '',
        emTrade: '',
        bcsCompanyCode: '',
        headHaulYN: false,
        transportationMode: '',
        driverValueABS: '',
        counterPostYN: false,
        buyingString: '',
        sellingService: '',
        sellingString: ''
      },
      {
        reportingPeriod: '2018007',
        pnlLine: 'PnL.VCC.031a',
        allocPnlLine: 'PnL.VCC.031a',
        accountingPeriod: '2018001',
        activityPeriod: '2018001',
        vessel: 'h7b',
        companyCode: 1000,
        amount: 277628,
        allocatedAmount: 277628,
        sourceAmount: 277628,
        intermediateResFlag: false,
        UnAllocAmountFlag: false,
        sourceId: 101,
        companyGroup: 'ML-SCL',
        finalResultYN: false,
        sapAccount: '',
        material: '',
        vendorId: '',
        centre: '',
        location: '',
        location5: '',
        vendorCtrCd: '',
        locCtrCd: '',
        corridorLoc1: '',
        corridorLoc2: '',
        voyage: '',
        orgVoyage: '',
        service: '',
        fromLocation: '',
        fromZone: '',
        toLocation: '',
        toZone: '',
        contSize: '',
        sapContSize: '',
        opContSize: '',
        contType: '',
        sapContType: '',
        opContType: '',
        breakBulkYN: false,
        string: '',
        stringCompanyCode: '',
        stringDirection: '',
        cargoType: '',
        moveType: '',
        shipmentNo: '',
        containerNo: '',
        portOfDischarge: '',
        portOfLoad: '',
        activityTs: '',
        allocMove: '',
        workSpaceId: '',
        ruleId: 0,
        stepId: 0,
        previousStepId: 0,
        stepSeqNo: 0,
        driverValue: '',
        runId: 0,
        portOptionYN: false,
        weightRestrictionYN: false,
        serviceType: '',
        ownerString: '',
        ownerCompanyCode: '',
        onOwnString: false,
        serviceStatus: '',
        weight: 0,
        liveReeferYN: false,
        serviceCompanyCode: '',
        inPnlLine: '',
        postingDate: '',
        freightType: '',
        salesOrderDocType: '',
        itemCategory: '',
        grossRevSource: '',
        fullEmptySeaLand: '',
        porLocation5: '',
        porActivityPeriod: '',
        podLocation5: '',
        podActivityPeriod: '',
        pobLocation5: '',
        porPodPob: '',
        emImportYN: false,
        emCostType: '',
        emSubCostType: '',
        contHighYN: false,
        vendorCtlOpcl: '',
        locCtrOpcl: '',
        emptyYN: false,
        operatorCompanyCode: '',
        emTrade: '',
        bcsCompanyCode: '',
        headHaulYN: false,
        transportationMode: '',
        driverValueABS: '',
        counterPostYN: false,
        buyingString: '',
        sellingService: '',
        sellingString: ''
      }
    ];
  }

}
