import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
// import { routing } from '../app.routing';
import { MaerskSmartTableComponent } from './maersk-smart-table/maersk-smart-table.component';
import { FormatCellPipe } from './maersk-smart-table/format-cell.pipe';
import { StyleCellDirective } from './maersk-smart-table/style-cell.directive';

import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxPaginationModule } from 'ngx-pagination';
import { OrderBy } from './maersk-smart-table/maersk-smart-table.orderByPipe';
import { TooltipModule } from 'ng2-tooltip';
import { UtcDatePipe } from './maersk-smart-table/maersk-smart-table.utcDatePipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ExcelService } from '../services/excel.service';
import { FrequencyMonths } from './maersk-smart-table/maersk-smart-table.frequencyMonths';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    // routing,
    Ng2SearchPipeModule,
    NgxPaginationModule,
    TooltipModule,
    NgbModule.forRoot()
  ],
  declarations: [MaerskSmartTableComponent, FormatCellPipe, StyleCellDirective, OrderBy, UtcDatePipe, FrequencyMonths],
  providers: [CurrencyPipe, ExcelService],
  exports: [MaerskSmartTableComponent, FormatCellPipe, StyleCellDirective, UtcDatePipe, FrequencyMonths]
})
export class SharedModule { }
