import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';

@Component({
  selector: 'app-support-create-ticket',
  templateUrl: './support-create-ticket.component.html',
  styleUrls: ['./support-create-ticket.component.css']
})
export class SupportCreateTicketComponent implements OnInit {

  ticket: FormGroup;
  comment: FormGroup;

  statuses = [{
    statusId: 1,
    status: 'New'
  },{
    statusId: 2,
    status: 'Assigned'
  }];

  priorities = [{
    priorityId: 1,
    priority: 'P1'
  },
  {
    priorityId: 2,
    priority: 'P2'
  }];

  severities = [{
    severityId: 1,
    severity: 'Low'
  },
{
  severityId: 2,
  severity: 'Medium'
}];

  constructor() { }

  ngOnInit() {

    this.ticket = new FormGroup({
      ticketId: new FormControl(''),
      ticketTitle: new FormControl(''),
      ticketDescription: new FormControl(''),
      ticketSeverityId: new FormControl(''),
      ticketPriorityId: new FormControl(''),
      ticketStatusId: new FormControl(''),
      productId: new FormControl(''),
      templateId: new FormControl(''),
      dueDate: new FormControl(''),
      ticketAssignedTo: new FormControl(''),
      ticketCreatedBy: new FormControl(''),
      ticketCreatedTimestamp: new FormControl(''),
      ticketUpdatedTimestamp: new FormControl('')
    });

    this.comment = new FormGroup({
      commentText: new FormControl(''),
    });
  }

}
