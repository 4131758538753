import { environment } from './../environments/environment';

export class AppSettings {
    //public static API_ENDPOINT = 'https://loadnowapi.azurewebsites.net/';
    //public static API_ENDPOINT = 'https://loadnowapitest.azurewebsites.net/';
    //public static API_ENDPOINT = 'https://loadnowapidev.azurewebsites.net/';
    //public static API_ENDPOINT = 'https://loadnowapiprod.azurewebsites.net/';
    //public static API_ENDPOINT = 'http://localhost:8080/';

    public static API_ENDPOINT = environment.app_Url;
    public static defaultTabSettings = {
      tableOptions: {
        multiSelect: true,
        sort: false,
        search: true,
        pagination: true,
        action: 3,
        add: false,
        edit: false,
        delete: false,
        needDownloadButton: true,
        enableFileUpload: true
      }
    };

    public static tabSettings = {
        'Reference': {
            tableSettings: [
                {
                    columnName: 'productFamily',
                    columnAlias: 'BI Product'
                },
                {
                    columnName: 'aliasName',
                    columnAlias: 'Table Name'
                },
                {
                  columnName: 'description',
                  columnAlias: 'Description'
                },
                {
                  columnName: 'businessOwner',
                  columnAlias: 'Business Owner'
                },
                {
                  columnName: 'lastUpdatedBy',
                  columnAlias: 'Last Updated By'
                },
                {
                  columnName: 'lastUpdatedTimestamp',
                  columnAlias: 'Last Updated Time',
                  format: 'datetime'
                }
            ],
            tableOptions: {
                multiSelect: true,
                sort: false,
                search: true,
                pagination: true,
                action: 1,
                edit: true,
                delete: false,
                add: true,
                needDownloadButton: false,
                hasRecordDownload: true,
                enableFileUpload: false
            }
        },
        'TemplateAccess': {
          tableSettings: [
              {
                  columnName: 'tableAlias',
                  columnAlias: 'Template Name'
              },
              {
                  columnName: 'accessType',
                  columnAlias: 'Access Type'
              },
              {
                columnName: 'frequencyMonths',
                columnAlias: 'Access Enabled Months',
                format: 'frequencyMonths'
            },
              {
                columnName: 'startDay',
                columnAlias: 'Access Start Day'
              },
              {
                columnName: 'endDay',
                columnAlias: 'Access End Day'
              }
          ],
          tableOptions: {
              multiSelect: true,
              sort: false,
              search: true,
              pagination: true,
              action: 1,
              add: true,
              edit: true,
              delete: true,
              needDownloadButton: false,
              enableFileUpload: false
          }
      },
        'UserProductRole': {
            tableSettings: [
                {
                  columnName: 'userId',
                  columnAlias: 'APM ID'
                },
                {
                  columnName: 'email',
                  columnAlias: 'EMAIL ADDRESS'
                },
                {
                  columnName: 'userName',
                  columnAlias: 'USER NAME'
                },
                {
                  columnName: 'productName',
                  columnAlias: 'BI PRODUCT'
                },
                {
                  columnName: 'roleName',
                  columnAlias: 'ROLE'
                }
              ],
              tableOptions: {
                multiSelect: true,
                sort: false,
                search: true,
                pagination: true,
                action: 4,
                add: true,
                edit: false,
                delete: true,
                needDownloadButton: false,
                enableFileUpload: false
              }
        },
        'TemplateAccessUser': {
          tableSettings: [
              {
                columnName: 'userId',
                columnAlias: 'APM ID'
              },
              {
                columnName: 'emailAddress',
                columnAlias: 'EMAIL ADDRESS'
              }
            ],
            tableOptions: {
              multiSelect: true,
              sort: false,
              search: true,
              pagination: true,
              action: 4,
              add: true,
              edit: false,
              delete: true,
              needDownloadButton: false,
              enableFileUpload: false
            }
      },
        'Triggers': {
            tableOptions: {
              multiSelect: true,
              sort: false,
              search: true,
              pagination: true,
              action: 2,
              add: false,
              edit: false,
              delete: false,
              needDownloadButton: false,
              enableFileUpload: false
            }
      }
    };
}
