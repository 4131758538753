export class ColumnSetting {
    columnName: string;
    columnAlias?: string;
    dataType?: string = "string";
    alternativeKeys?: string[];
    autoIncrement?: boolean = false;
}

export class SmartTableOptions {
    search?: boolean = false;
    multiSelect?: boolean = false;
    sort?: boolean = false;
    pagination?: boolean = false;
    action?: number = 0;//Update according to the number of tables with action required in the project
}