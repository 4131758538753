import { Component, OnInit ,ViewChild, ElementRef} from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl  } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-generate-script',
  templateUrl: './generate-script.component.html',
  styleUrls: ['./generate-script.component.css']
})
export class GenerateScriptComponent implements OnInit {

  productFamily: any[];
  tables: any[];
  selectedProductFamily: any;
  selectedTable: any;
  referencetableScript: string;
  createTableScript: string;
  columnMap: any[];
  backupData: any[];
  response: any;
  @ViewChild('viewModal') viewModal: ElementRef;

  constructor(private dataService: DataService, private modalService: NgbModal) { }

  ngOnInit() {

    this.dataService.getData('ProductFamily').subscribe(data => {

      const productArray: any[] = new Array();
      data.forEach(product => {
        if (productArray.find(item => item.familyId === product.familyId) == null) {
          productArray.push({familyId: product.familyId, productFamily: product.productFamily});
        }
      });
      this.productFamily = productArray;

    });
    this.dataService.getManualUploadTablesWithProduct().subscribe(data => { 
      this.backupData = data;
    });
    // this.dataService.getMasterDataTablesWithProduct().subscribe(data => { 
    //   this.backupData.push(data);
    // });

  }



  changeProductFamily() {

    this.tables = this.backupData.filter(item => {
      return item.productFamily === this.selectedProductFamily;
    });
  }
  getScript() {
    this.dataService.getScript(this.selectedTable,this.selectedProductFamily).subscribe(data => {
      this.response = data;
    });
    this.modalService.open(this.viewModal);

  }

}
