import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-trigger-product',
  templateUrl: './trigger-product.component.html',
  styleUrls: ['./trigger-product.component.css']
})
export class TriggerProductComponent implements OnInit {

  constructor(private dataService: DataService) { }

  productFamilies: any[];
  selectedFamilyId: Number;
  ngOnInit() {
    this.getProducts();
  }
  getProducts() {
    this.dataService.getAllProductFamilyForAdminAndReloader().subscribe(data => this.productFamilies = data);
    }
}
