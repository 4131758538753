import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-template-access',
  templateUrl: './template-access.component.html',
  styleUrls: ['./template-access.component.css']
})
export class TemplateAccessComponent implements OnInit {

  constructor(private dataService: DataService, private router: Router) { }

  productFamilies: any[];
  selectedFamilyId: Number;
  explicitAccessTables: any[];
  selectedTemplateId: Number;
  ngOnInit() {
    this.getProducts();
  }
  getProducts() {
    this.dataService.getAllProductFamiliesForAdmins().subscribe(data => this.productFamilies = data);
  }

  changeProductFamily() {
    this.dataService.getExplicitAccessTablesData(this.selectedFamilyId.toString()).subscribe(data => this.explicitAccessTables = data);
  }

  actionRaised(paramArray) {
      this.router.navigateByUrl('/home/' + paramArray[1].tableName);
  }
}
