import { Component, OnInit, Input, OnChanges, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';
import { ColumnSetting, SmartTableOptions } from 'src/app/shared/maersk-smart-table/maersk-smart-table.model';
import { AppSettings } from 'src/app/appSettings';
import { DataService } from 'src/app/services/data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-template-access-users-display',
  templateUrl: './template-access-users-display.component.html',
  styleUrls: ['./template-access-users-display.component.css']
})
export class TemplateAccessUsersDisplayComponent implements OnInit, OnChanges {

  param: Number;
  userTemplateAccess: FormGroup;

  tableSettings: ColumnSetting[];
  maerskData: any[];
  roles: any[];
  templateAccesses: any[];
  selectedTemplateAccess: any;
  tableOptions: SmartTableOptions;
  @ViewChild('addModal') addPopup: ElementRef;

  constructor(private dataService: DataService, private modalService: NgbModal, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {

    this.userTemplateAccess = new FormGroup({
      userId: new FormControl(''),
      templateAccessId: new FormControl('')
    });
    this.route.params.subscribe(params => {
      const paramVar = params['query'];
      this.param = paramVar;
    });

    this.tableOptions = AppSettings.tabSettings['TemplateAccessUser'].tableOptions;
      this.tableSettings = AppSettings.tabSettings['TemplateAccessUser'].tableSettings;
      this.getData();
  }

  ngOnChanges() {
    if (this.param) {
      this.getData();
    }
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   const prId: SimpleChange = changes.productId;
  //   console.log('prev value: ', name.previousValue);
  //   console.log('got name: ', name.currentValue);
  //   this._name = name.currentValue.toUpperCase();
  // }

  OpenAddUser(addModal) {
    this.modalService.open(addModal);
    }

  getData() {
    if (this.param) {
    this.dataService.getUsersForTemplateAccessId(this.param.toString()).subscribe(data => {
      this.maerskData = data;
    });

    this.dataService.getTemplateAccessFromTemplateAccessId(this.param.toString()).subscribe(accessTypeData => {
      this.selectedTemplateAccess = accessTypeData;
      this.templateAccesses = [];
      this.templateAccesses.push(accessTypeData);
    });
  }
  }

  onSubmit(userTemplateAccessObj) {
    this.dataService.CreateTemplateUserAccess(userTemplateAccessObj.value).subscribe(success => {
      this.getData();
    }, error => {
      alert('User could not be added');
    });
    this.modalService.dismissAll();
  }

  actionRaised(func) {
    let userObj = func[1];
    console.log(userObj);
    let action: number = func[0];
    //1- Add User 4-Delete
    if (action === 1) {
      this.dataService.CreateUser(userObj)
        .subscribe(result => {
          console.log('Add User Successful');
        },
        error => {
          console.log('Add User API Call failed');
        },
        () => {
          // 'onCompleted' callback.
          // No errors, route to new page here
        });
    } else if (action === 4) {
      this.dataService.DeleteUserTemplateAccess(userObj)
        .subscribe(
        result => {
          console.log('Delete Successful');
        },
        error => {
          console.log('Delete failed');
        },
        () => {
          this.getData();
        }
        );
    } else if (action === 6) {
      this.OpenAddUser(this.addPopup);
    }
  }

}
