import { Component, OnInit, Input, OnChanges, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';
import { ColumnSetting, SmartTableOptions } from 'src/app/shared/maersk-smart-table/maersk-smart-table.model';
import { AppSettings } from 'src/app/appSettings';
import { DataService } from 'src/app/services/data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';

@Component({
  selector: 'app-user-product-role-display',
  templateUrl: './user-product-role-display.component.html',
  styleUrls: ['./user-product-role-display.component.css'],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProductRoleDisplayComponent implements OnInit, OnChanges {

  @Input() productId: Number;
  @Input() productFamilies: any[];
  @ViewChild('addModal') addPopup: ElementRef;
  productName: any;

  user: FormGroup;

  tableSettings: ColumnSetting[];
  maerskData: any[];
  roles: any[];
  tableOptions: SmartTableOptions;

  constructor(private dataService: DataService, private modalService: NgbModal) { }

  ngOnInit() {
    this.user = new FormGroup({
      userId: new FormControl(''),
      productId: new FormControl(''),
      roleId: new FormControl(''),
      email: new FormControl('')
    });

    this.productName = this.productFamilies.filter(product => product.familyId == this.productId)[0].productFamily;

    this.tableOptions = AppSettings.tabSettings['UserProductRole'].tableOptions;
      this.tableSettings = AppSettings.tabSettings['UserProductRole'].tableSettings;
      this.getData();
  }

  ngOnChanges() {
    if (this.productId) {
      this.getData();
    }
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   const prId: SimpleChange = changes.productId;
  //   console.log('prev value: ', name.previousValue);
  //   console.log('got name: ', name.currentValue);
  //   this._name = name.currentValue.toUpperCase();
  // }

  OpenAddUser(addModal) {
    this.modalService.open(addModal);
    }

  getData() {
    if (this.productId) {
    this.dataService.getUserProductRolesData(this.productId.toString()).subscribe(data => {
      this.maerskData = data;
    });
    this.dataService.getUserRolesData().subscribe(rolesData => {
      this.roles = rolesData;
    });
  }
  }

  onSubmit(userObj) {
    this.dataService.CreateUser(userObj.value).subscribe(success => {
      this.getData();
    }, error => {
      alert('User could not be added');
    });
    this.modalService.dismissAll();
  }

  

  actionRaised(func) {
    let userObj = func[1];
    console.log(userObj);
    let action: number = func[0];
    //1- Add User 4-Delete
    if (action === 6) {
      this.OpenAddUser(this.addPopup);
    } else if (action === 4) {
      this.dataService.DeleteUser(userObj)
        .subscribe(
        result => {
          console.log('Delete Successful');
        },
        error => {
          console.log('Delete failed');
        },
        () => {
          this.getData();
        }
        );
    }
  }

}
