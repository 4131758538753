import { Component, OnInit} from '@angular/core';
import { AdalService } from 'adal-angular4';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { DataService } from './services/data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'MaerskFin';
  currentURL: string;
  isLoggedIn = false;

  constructor (private adalService: AdalService, private router: Router , private dataService : DataService) {
    adalService.init(environment.config);
  }

  ngOnInit(): void {
    this.adalService.handleWindowCallback();
    if (this.adalService.userInfo.authenticated) {
        const userInfo = this.adalService.userInfo;
        this.isLoggedIn = true;
        let roles = sessionStorage.getItem('Roles');
        if (!roles) {
          this.dataService.getUserRoles().subscribe(data => {
            sessionStorage.setItem('Roles', JSON.stringify(data));
          });
        }
    } else if ( this.adalService.userInfo.error == null) {
      this.adalService.login();
    } else {
      this.isLoggedIn = false;
    }
    this.dataService.getUserRoles().subscribe(data => {
      sessionStorage.setItem('Roles', JSON.stringify(data));
    }
   );
}

loggedIn() {
  if (this.adalService.userInfo.authenticated) {
    return true;
  } else {
    return false;
  }
}

}
