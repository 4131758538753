import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-schema',
  templateUrl: './schema.component.html',
  styleUrls: ['./schema.component.css']
})
export class SchemaComponent implements OnInit {

  constructor(private dataService: DataService, private route: ActivatedRoute, private router: Router) { }
data: any;
param: string;

  ngOnInit() {
    this.route.params.subscribe(params => {
      const paramVar = params['query'];
      this.param = paramVar;
      this.getData(this.param);
    });
  }

  getData(queryString: string) {
    this.dataService.getTableSchemaData(queryString).subscribe(response => {
      this.data = response;
    }, error => {

    });
  }

  checkAndExists(num1: any, num2: any) {
    // tslint:disable-next-line:no-bitwise
    if ((num1 & num2) > 0) {
      return true;
    } else {
      return false;
    }
  }
}
