import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  //constructor(private dataService: DataService) { }

  showStatusHeader = false;
  productFamily: any[];
  tables: any[];
  selectedProductFamily: any;
  statSuccess = false;
  showStatus = false;
  selectedTable: any;
  param: string;
  backupData: any[];
  errorMessages: any[] = new Array();
  fileUploadStatus = false;
  fileExt = 'XLSX, XLS';
  maxFiles = 1;
  maxSize = 100;
  errors: string[];
  progressSuccess = false;
  progressValue = 0;
  progressTimeout = null;
  showSuccess = false;
  showError = false;
  pageType: string;
  fileDetails = {
    name: null as string,
    size: null as number
  };
  @ViewChild('file') maerskFileInput;


  // ngOnInit() {
  //   this.getData();
  // }

  // getData() {
  //   this.dataService.getManualUploadTablesWithProduct().subscribe(data => {
  //     this.backupData = data;
  //     this.reduceToUniqueProduct();
  //   });
  // }

  constructor(private dataService: DataService, private route: ActivatedRoute, private router: Router ) {}

  ngOnInit() {

    this.route.params.subscribe(params => {
      const paramVar = params['query'];
      this.param = paramVar;
      if (this.param.toLowerCase() === 'manualupload') {
      this.pageType = 'manualUpload';
      this.dataService.getManualUploadTablesWithProduct().subscribe(data => {
        this.backupData = data;
        this.reduceToUniqueProduct();
      });
      } else if (this.param.toLowerCase() === 'masterupload') {
        this.pageType = 'masterupload';
        this.dataService.getMasterDataTablesWithProduct().subscribe(data => {
          this.backupData = data;
          this.reduceToUniqueProduct();
        });
      } else {
        this.pageType = 'manualUpload';
        this.param = 'manualupload';
      }
      this.getData(this.param);
    });
  }

  getData(query) {

    if (this.param.toLowerCase() === 'manualupload') {
      this.dataService.getManualUploadTablesWithProduct().subscribe(data => {
        this.backupData = data;
        this.reduceToUniqueProduct();
      });
    } else {
      this.dataService.getMasterDataTablesWithProduct().subscribe(data => {
        this.backupData = data;
        this.reduceToUniqueProduct();
      });
    }
  }


  reduceToUniqueProduct() {
    const productArray: any[] = new Array();
    this.backupData.forEach(product => {
      if (productArray.find(item => item.familyId === product.familyId) == null) {
        productArray.push({familyId: product.familyId, productFamily: product.productFamily});
      }
    });
    this.productFamily = productArray;
  }

  changeProductFamily() {
    this.tables = this.backupData.filter(item => {
      return item.productFamily === this.selectedProductFamily;
    });
  }

  private isValidFiles(files) {
    // Check Number of files
    if (files.length > this.maxFiles) {
      this.showStatus = true;
      const statusMessage = 'Error: At a time you can upload only ' + this.maxFiles + ' files';
      this.errorMessages.push({message: '', statusMessage: statusMessage});
      return false;
    }
    this.isValidFileExtension(files);
    return this.errorMessages.length === 0;
  }
  private isValidFileExtension(files) {
    // Make array of file extensions
    const extensions = (this.fileExt.split(','))
      .map(function (x) { return x.toLocaleUpperCase().trim(); });
    for (let i = 0; i < files.length; i++) {
      // Get file extension
      const ext = files[i].name.toUpperCase().split('.').pop() || files[i].name;
      // Check the extension exists
      const exists = extensions.includes(ext);
      if (!exists) {
        this.showStatus = true;
        this.showStatusHeader = true;
        const statusMessage = 'Please upload a valid file format: ' + files[i].name;
        this.errorMessages.push({message: '', statusMessage: statusMessage});
      } else {
      // Check file size
      this.isValidFileSize(files[i]);
      }
    }
  }

  private isValidFileSize(file) {
    const fileSizeinMB = file.size / (1024 * 1000);
    const size = Math.round(fileSizeinMB * 100) / 100; // convert upto 2 decimal place
    if (size > this.maxSize) {
      this.showStatus = true;
      const statusMessage = 'Error (File Size): ' + file.name + ': exceed file size limit of'  + this.maxSize + 'MB ( ' + size + 'MB )';
      this.errorMessages.push({message: 'FAILURE', statusMessage: statusMessage});
    }
  }

  onFileChange(event) {

    this.showStatusHeader = false;
    const files = event.target.files;
    let sizeInMB = files[0].size / (1024 * 1000);
    sizeInMB = Math.round(sizeInMB * 100) / 100; // convert upto 2 decimal place
    this.fileDetails.name = files[0].name;
    this.fileDetails.size = sizeInMB;
    this.saveFiles(files, function(status) {
      // if (status === 'success') {
      //   this.fileUploadStatus = true;
      // } else {
      //   this.fileUploadStatus = false;
      // }
    });
    this.fileUploadStatus = true;
    this.maerskFileInput.nativeElement.value = '';
  }

  private updateProgressSuccess() {
    this.showError = false;
    this.showSuccess = true;
    this.progressValue = 100;
  }

  private updateProgressError() {
    this.showSuccess = false;
    this.showError = true;
    this.progressValue = 100;
  }

clearErrorMessages() {
  this.errorMessages = new Array();
}

  saveFiles(files, callback) {
    this.clearErrorMessages();
    if (this.selectedTable) {
      this.showStatus = true;
    this.progressValue = 0;
    this.showError = false;
    this.showSuccess = false;
    this.progressTimeout = setInterval(() => {
      if (this.progressValue <= 80) {
        this.progressValue += 10;
      }
    }, 1000);
    this.errors = []; // Clear error
    // Validate file size and allowed extensions
    if (files.length > 0 && (!this.isValidFiles(files))) {
      clearInterval(this.progressTimeout);
      this.updateProgressError();
      return;
    }
    if (files.length > 0) {
      const formData: FormData = new FormData();
      for (let j = 0; j < files.length; j++) {
        formData.append('file', files[j], files[j].name);
      }
      formData.append('typeOfFile', this.selectedTable);
      this.errorMessages.push({message: '', statusMessage: 'File is being Uploaded. Please Wait...'});
      this.dataService.UploadFile(formData)
        .subscribe(
        success => {
          this.showStatus = true;
          if (success.message === true) {
            this.clearErrorMessages();
            this.showStatusHeader = true;
            this.errorMessages.push({message: '', statusMessage: 'File validated and Uploaded Successfully'});
            this.statSuccess = true;
          } else {
            this.clearErrorMessages();
            this.showStatusHeader = true;
          this.errorMessages.push({message: '', statusMessage: success.statusMessage});
          this.statSuccess = false;
          }
          this.updateProgressSuccess();
          clearInterval(this.progressTimeout);
          setTimeout(() => {
            this.progressTimeout = null;
          }, 10000);
          callback('success');
        },
        error => {
          this.updateProgressError();
          clearInterval(this.progressTimeout);
          this.showStatus = true;
          this.statSuccess = false;
          this.clearErrorMessages();
          this.errorMessages.push({message: '', statusMessage: JSON.stringify(error)});
          callback('error');
        });
    }
  } else {
    this.showStatus = true;
    this.showStatusHeader = true;
    this.errorMessages.push({message: 'FAILED', statusMessage: 'Please select the Template before uploading the file and upload the file'});
   }
  }
}
