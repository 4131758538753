import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-table',
  templateUrl: './create-table.component.html',
  styleUrls: ['./create-table.component.css']
})
export class CreateTableComponent implements OnInit {

  table: FormGroup;
  // table = {
  //   tableName: '',
  //   tableAliasName: '',
  //   columns: [{
  //     columnName: '',
  //     columnAliasName: '',
  //     dataType: '',
  //     length: 0,
  //     precision: 0,
  //     allowNulls: false,
  //     isPrimaryKey: false,
  //     isUniqueKey: false,
  //     isAutoIncrement: false
  //   }]
  // };
  // createTableForm: FormGroup;

  productFamilies: any[];

  constructor(private fb: FormBuilder, private dataService: DataService, private router: Router) {
    // this.createTableForm = this.fb.group({
    //   tableName: '',
    //   tableAliasName: '',
    //   columns: this.fb.array([])
    // });
   }

  // dataTypes = [{
  //   'key': 'int',
  //   'value': 'int',
  //   'hasLength': false,
  //   'hasPrecision': false
  // },
  // {
  //   'key': 'bigint',
  //   'value': 'bigint',
  //   'hasLength': false,
  //   'hasPrecision': false
  // }, {
  //   'key': 'tinyint',
  //   'value': 'tinyint',
  //   'hasLength': false,
  //   'hasPrecision': false
  // }, {
  //   'key': 'char',
  //   'value': 'char',
  //   'hasLength': true,
  //   'hasPrecision': false
  // },
  // {
  //   'key': 'varchar',
  //   'value': 'varchar',
  //   'hasLength': true,
  //   'hasPrecision': false
  // }, {
  //   'key': 'decimal',
  //   'value': 'decimal',
  //   'hasLength': true,
  //   'hasPrecision': true
  // }, {
  //   'key': 'datetime',
  //   'value': 'datetime',
  //   'hasLength': false,
  //   'hasPrecision': false
  // }, {
  //   'key': 'datetime2',
  //   'value': 'datetime2',
  //   'hasLength': false,
  //   'hasPrecision': true
  // }];

  dataTypes = [
    'int',
    'bigint',
    'bit',
    'char',
    'varchar',
    'decimal',
    'datetime',
    'datetime2',
    'date',
    'time'
  ];

  days = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31
  ];

  destinations = [
    'table',
    'adls',
    'table and adls',
    'table, blob and adls'
  ];


  months = [{
    'key': 'Jan',
    'value': 1
  },
  {
    'key': 'Feb',
    'value': 2
  },
  {
    'key': 'Mar',
    'value': 4
  },
  {
    'key': 'Apr',
    'value': 8
  },
  {
    'key': 'May',
    'value': 16
  },
  {
    'key': 'Jun',
    'value': 32
  },
  {
    'key': 'Jul',
    'value': 64
  },
  {
    'key': 'Aug',
    'value': 128
  },
  {
    'key': 'Sep',
    'value': 256
  },
  {
    'key': 'Oct',
    'value': 512
  },
  {
    'key': 'Nov',
    'value': 1024
  },
  {
    'key': 'Dec',
    'value': 2048
  }
];

  customValidationTypes = {
    'int': [{ 'key': 'range', 'value': 'range' },
    { 'key': 'match regex', 'value': 'regex' }],
    'varchar': [{ 'key': 'starts with', 'value': 'starts with' },
    { 'key': 'ends with', 'value': 'ends with' },
    { 'key': 'contains', 'value': 'contains' },
    { 'key': 'match regex', 'value': 'regex' },
    { 'key': 'in (,) seperated', 'value': 'in' }],
    'char': [{ 'key': 'starts with', 'value': 'starts with' },
    { 'key': 'ends with', 'value': 'ends with' },
    { 'key': 'contains', 'value': 'contains' },
    { 'key': 'match regex', 'value': 'regex' },
    { 'key': 'in (,) seperated', 'value': 'in' }],
    'decimal': [{ 'key': 'range', 'value': 'range' }],
    'datetime2': [{ 'key': 'day range', 'value': 'drange' },
    { 'key': 'month range', 'value': 'mrange' },
    { 'key': 'year range', 'value': 'yrange' },
    { 'key': 'date range', 'value': 'dtrange' }],
    'datetime': [{ 'key': 'day range', 'value': 'drange' },
    { 'key': 'month range', 'value': 'mrange' },
    { 'key': 'year range', 'value': 'yrange' },
    { 'key': 'date range', 'value': 'dtrange' }],
    'date': [{ 'key': 'day range', 'value': 'drange' },
    { 'key': 'month range', 'value': 'mrange' },
    { 'key': 'year range', 'value': 'yrange' },
    { 'key': 'date range', 'value': 'dtrange' }],
    'bigint': [{ 'key': 'range', 'value': 'range' },
    { 'key': 'match regex', 'value': 'regex' }],
  };

  ngOnInit() {
    this.getProducts();
    this.table = new FormGroup({
      tableName: new FormControl(''),
      tableAliasName: new FormControl(''),
      familyId: new FormControl(''),
      destination: new FormControl(''),
      rawFolder: new FormControl(''),
      finalFolder: new FormControl(''),
      container: new FormControl(''),
      masterData: new FormControl(''),
      truncateOnLoad: new FormControl(''),
      needBlobTrigger: new FormControl(''),
      needManualBlobTrigger: new FormControl(''),
      triggerFolder: new FormControl(''),
      triggerContainer: new FormControl(''),
      dimensionTable: new FormControl(''),
      startDay: new FormControl(''),
      endDay: new FormControl(''),
      dimensionReference: new FormControl(''),
      explicitAccess: new FormControl(''),
      frequencyMonths: new FormControl(''),
      selectedMonths: new FormControl(''),
      autoUpdateTimeStamp : new FormControl(''),
      autoUpdateUserId : new FormControl(''),
      businessOwner: new FormControl(''),
      description: new FormControl(''),
      columns: new FormArray([
        this.initColumns()
      ])
    });
  }

getProducts() {
this.dataService.getProductFamilies().subscribe(data => this.productFamilies = data);
}

  initColumns() {
    return new FormGroup({
        columnName: new FormControl(''),
        columnAlias: new FormControl(''),
        dataType: new FormControl(''),
        length: new FormControl(''),
        columnPrecision: new FormControl(''),
        allowNulls: new FormControl(true),
        primaryKey: new FormControl(false),
        uniqueKey: new FormControl(false),
        autoIncrement: new FormControl(false),
        customValidation: new FormControl(false),
        customValidationType: new FormControl(''),
        min: new FormControl(''),
        max: new FormControl(''),
        validatorValue: new FormControl(''),
        dateMin: new FormControl(''),
        dateMax: new FormControl(''),
        dimensionReferenceTable : new FormControl(''),
        dimensionReferenceColumn : new FormControl('')
        
    });
  }
  addNewColumn() {
    const control = <FormArray>this.table.get('columns');
    control.push(this.initColumns());
  }

  deleteColumn(control, index) {
    control.removeAt(index);
  }

  onSubmit(table) {
    // const formData: FormData = new FormData();
    // formData.append('table', table.value);

    if (table.value.selectedMonths.length > 0) {
      let sum = 0;
      table.value.selectedMonths.forEach(monthValue => {
        const vlue: number =  parseInt(monthValue);
        sum = sum + vlue;
      });
      table.value.frequencyMonths = sum;
    }

    this.dataService.CreateTable(table.value).subscribe((data) => {
      if (data['status']) {
        alert(data['statusMessage']);
        this.router.navigateByUrl('/');
      } else {
        alert(data['statusMessage']);
      }
    }, error => {
      alert('Unable to save table, Please try again');
    });
  }

}
