import {
    Directive, ElementRef,
    Input, Renderer, OnInit
} from '@angular/core';
@Directive({ selector: '[appMaerskStyleCell]' })
export class StyleCellDirective implements OnInit {
    @Input() maerskStyleCell: string;
    constructor(
        private el: ElementRef,
        private renderer: Renderer) { }
    ngOnInit() {
        if (this.maerskStyleCell === undefined) {
            this.renderer.setElementStyle(
                this.el.nativeElement,
                'color',
                '#dcdcdc');
            this.renderer.setElementStyle(
                this.el.nativeElement,
                'text-align',
                'center');
        }
        if (typeof this.maerskStyleCell === 'number') {
            this.renderer.setElementStyle(
                this.el.nativeElement,
                'text-align',
                'right');
        }
    }
}