// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  config: {
    tenant: '05d75c05-fa1a-42e7-9cf1-eb416c396f2d',
    clientId: '9026e17a-5057-4c2f-8e86-b89de2b035c4',
    endpoints: {
      'https://graph.microsoft.com': '00000003-0000-0000-c000-000000000000',
      'http://localhost:4200': '00000003-0000-0000-c000-000000000000',
      'http://localhost:8080': '00000003-0000-0000-c000-000000000000',
      'http://localhost:8080/getData/ReferenceWithProduct': '00000003-0000-0000-c000-000000000000'
    },
    cacheLocation: 'sessionStorage'
  },
  app_Url: 'https://loadnowapidev.azurewebsites.net/'
  //app_Url: 'http://localhost:8080/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
